<template>
  <div class="supply">
    <img src="@/assets/supply/en/Slice1-min.png" alt="">
    <img src="@/assets/supply/en/Slice2-min.png" alt="">
    <img src="@/assets/supply/en/Slice3-min.png" alt="">
    <img src="@/assets/supply/en/Slice4-min.png" alt="">
    <img src="@/assets/supply/en/Slice5-min.png" alt="">
    <div class="haggle-content haggle-content1">
      <div class="header">
          <img src="@/assets/supply/en/hotselling-min.png" alt="">
          <div @click="viewMore('厨房集合地')">more ></div>
      </div>
      <van-swipe :autoplay="5000" :stop-propagation="false" :show-indicators="true">
        <van-swipe-item v-for="(item, index) in haggleList1" :key="index" class="haggle-goods">
          <div class="haggle-goods-item" v-for="(it, i) in item" :key="i" @click="viewDetail(it)">
            <img v-if="it.image_url" :src="it.image_url" alt="">
            <p>{{it.goods_info.name}}</p>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <img src="@/assets/supply/en/Slice6-min.png" alt="">
    <img src="@/assets/supply/en/Slice7-min.png" alt="">
    <img src="@/assets/supply/en/Slice8-min.png" alt="">
    <div class="haggle-content haggle-content4">
      <div class="header">
          <img src="@/assets/supply/en/hotselling-min.png" alt="">
          <div @click="viewMore('毛孩子星球')">more ></div>
      </div>
      <van-swipe :autoplay="5000" :stop-propagation="false" :show-indicators="true">
        <van-swipe-item v-for="(item, index) in haggleList2" :key="index" class="haggle-goods">
          <div class="haggle-goods-item" v-for="(it, i) in item" :key="i" @click="viewDetail(it)">
            <img v-if="it.image_url" :src="it.image_url" alt="">
            <p>{{it.goods_info.name}}</p>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <img src="@/assets/supply/en/Slice9-min.png" alt="">
    <img src="@/assets/supply/en/Slice10-min.png" alt="">
    <img src="@/assets/supply/en/Slice11-min.png" alt="">
    <div class="haggle-content haggle-content2">
      <div class="header">
          <img src="@/assets/supply/en/hotselling-min.png" alt="">
          <div @click="viewMore('运动尖货')">more ></div>
      </div>
      <van-swipe :autoplay="5000" :stop-propagation="false" :show-indicators="true">
        <van-swipe-item v-for="(item, index) in haggleList3" :key="index" class="haggle-goods">
          <div class="haggle-goods-item" v-for="(it, i) in item" :key="i" @click="viewDetail(it)">
            <img v-if="it.image_url" :src="it.image_url" alt="">
            <p>{{it.goods_info.name}}</p>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <img src="@/assets/supply/en/Slice12-min.png" alt="">
    <img src="@/assets/supply/en/Slice13-min.png" alt="">
    <img src="@/assets/supply/en/Slice14-min.png" alt="">
    <div class="haggle-content haggle-content3">
      <div class="header">
          <img src="@/assets/supply/en/hotselling-min.png" alt="">
          <div @click="viewMore('公寓派对')">more ></div>
      </div>
      <van-swipe :autoplay="5000" :stop-propagation="false" :show-indicators="true">
        <van-swipe-item v-for="(item, index) in haggleList4" :key="index" class="haggle-goods">
          <div class="haggle-goods-item" v-for="(it, i) in item" :key="i" @click="viewDetail(it)">
            <img v-if="it.image_url" :src="it.image_url" alt="">
            <p>{{it.goods_info.name}}</p>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { searchGoods } from '@/api/en/search.js'

export default {
    data() {
        return {
            haggleList1: [],
            haggleList2: [],
            haggleList3: [],
            haggleList4: [],
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
          searchGoods({
                search: '厨房集合地',
                status: 1,
                page: 1
            }).then(res => {
                if(res) {
                    let len = 0
                    if(res.data.data.length > 9) {
                        len = 9
                    } else {
                        len = res.data.data.length
                    }
                    for(var i=0;i< len; i+=3) {
                        this.haggleList1.push(res.data.data.slice(i, i + 3))
                    }
                }
            });
            searchGoods({
                search: '毛孩子星球',
                status: 1,
                page: 1
            }).then(res => {
                if(res) {
                    let len = 0
                    if(res.data.data.length > 9) {
                        len = 9
                    } else {
                        len = res.data.data.length
                    }
                    for(var i=0;i< len; i+=3) {
                        this.haggleList2.push(res.data.data.slice(i, i + 3))
                    }
                }
            });
            searchGoods({
                search: '运动尖货',
                status: 1,
                page: 1
            }).then(res => {
                if(res) {
                    let len = 0
                    if(res.data.data.length > 9) {
                        len = 9
                    } else {
                        len = res.data.data.length
                    }
                    for(var i=0;i< len; i+=3) {
                        this.haggleList3.push(res.data.data.slice(i, i + 3))
                    }
                }
            });
            searchGoods({
                search: '公寓派对',
                status: 1,
                page: 1
            }).then(res => {
                if(res) {
                    let len = 0
                    if(res.data.data.length > 9) {
                        len = 9
                    } else {
                        len = res.data.data.length
                    }
                    for(var i=0;i< len; i+=3) {
                        this.haggleList4.push(res.data.data.slice(i, i + 3))
                    }
                }
            });
        },
        viewDetail(item) {
          this.$router.push({path: `/en/goods/detail/${item.id}`, query: {pre: item.pre_info.is_pre == 1 ? 1 : 0}})
        },
        viewMore(value) {
          this.$router.push(`/en/search?searchKey=${value}`)
        }
    }
}
</script>

<style lang="less" scoped>
.supply {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    position: relative;

    img {
        display: block;
        width: 100%;
    }

    .haggle-content {
        position: absolute;
        width: 350px;
        height: 230px;
        background-color: #F2FEFA4D;
        border-radius: 12px;
        padding: 12px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
                width: auto;
                height: 30px;
                display: block;
            }
            div {
                font-size: 13px;
                color: #FFFFFF;
            }
        }
    }

    .haggle-goods {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      
      .haggle-goods-item {
        width: 100px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 6px;
        }
        p {
          margin-top: 3px;
          color: #fff;
          font-size: 13px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    /deep/ .van-swipe {
        height: 170px;
        .van-swipe__indicators {
            bottom: 0px;
            .swipe__indicator {
                background-color: #D9D9D9 !important;
            }
            .van-swipe__indicator--active {
                background-color: #FFF412 !important;
                width: 14px;
                border-radius: 12px;
            }
        }
    }

    .haggle-content1 {
        top: 1405px;
    }

    .haggle-content2 {
        top: 2390px;
    }

    .haggle-content3 {
        top: 3400px;
    }

    .haggle-content4 {
        top: 4310px;
    }
}
</style>