/** 
 *   搜索
 */
import { get, del } from '@/untils/js/axios.js'

// 获取搜索历史
export const getHistory = params => get('/search/history', params)

// 删除搜索历史
export const delHistory = id => del(`/search/history-del/${id}`)

// 获取热门
export const getHotSearch = params => get('/search/hot', params)

// 搜索商品
export const searchGoods = params => get('/search/goods/index', params)

// 分词搜索
export const cut_search = params => get(`/search/keywords/cut`, params)

// 小程序搜索
export const search_mini = params => get('/search/goods/index', params) 

// 搜索礼物
export const searchGift = params => get('/search/goods/gift', params)

// 店铺搜索
export const searchMerchant = (id, params) => get(`/search/goods/${id}`, params)

// 预售搜索
export const searchPresale = params => get('/search/goods/pre', params)